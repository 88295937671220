import { Fab, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Snackbar, Alert } from "@mui/material"
import FeedbackIcon from '@mui/icons-material/Feedback'
import { useState } from "react"

type Severity = "error" | "success" | "info" | "warning" | undefined

function FeedbackButton() {
    const [ open, setOpen ] = useState(false)
    const [ openMsg, setOpenMsg ] = useState(false)
    const [ feedbackMsg, setFeedbackMsg ] = useState("")
    const [ msgSeverity, setMsgSeverity ] = useState<Severity>("success")
    const [ resultMsg, setResultMsg ] = useState("")

    const handleOpen = () => {
        setOpen(true)
    }

    const handleConfirm = () => {
        setOpen(false)
        fetchFeedback()
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleMsgClose = () => {
        setOpenMsg(false)
    }

    const fetchFeedback = async () => {
        try {
            const response = await fetch("https://api.cinapps.ch/feedback", {
                method: "POST",
                body: JSON.stringify({ message: feedbackMsg })
            })
            const responseData = await response.json()
            if(response.status === 200){
                setResultMsg("Ton message a été transmis !")
                setMsgSeverity("success")
                setFeedbackMsg("")
            } else {
                setResultMsg(responseData.msg)
                setMsgSeverity("error")
            }
            setOpenMsg(true)
        } catch(err) {
            setResultMsg("Erreur serveur")
            setMsgSeverity("error")
            setOpenMsg(true)
        } finally {}
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openMsg}
                onClose={handleMsgClose}
                autoHideDuration={4000}>
                <Alert onClose={handleMsgClose} severity={msgSeverity} sx={{ width: '100%' }}>
                    {resultMsg}
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Remplissez le champ ci-dessous afin de me laisser un feedback :
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="feedbackMsg"
                        label="Message"
                        fullWidth
                        multiline={true} 
                        rows={4}
                        variant="standard"
                        placeholder="Entrez votre message ici..." 
                        onChange={(e) => setFeedbackMsg(e.target.value)} 
                        value={feedbackMsg} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm}>Envoyer</Button>
                    <Button onClick={handleClose}>Annuler</Button>
                </DialogActions>
            </Dialog>
            <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: 16, right: 16 }} onClick={handleOpen}>
                <FeedbackIcon />
            </Fab>
        </div>
    )
}

export default FeedbackButton