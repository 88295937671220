import { List, ListSubheader, Link, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { useSelector } from "react-redux"
import { selectSelectedItemMenu } from "../features/selectors"
import { useDispatch } from "react-redux"
import { ACT_MENU_SELECT_ITEM } from "../features/actions"
import { useNavigate } from "react-router-dom"

function Menu({ mobileOpen, setMobileOpen }: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selectedItem = useSelector(selectSelectedItemMenu())
    const menuItemsList = [
        [ "Affichettes", "/flyers", <PhotoSizeSelectActualIcon fontSize="small" /> ],
        [ "DCP/KDM", "/dcp-kdm", <VpnKeyIcon fontSize="small" /> ], 
        //[ "Gestion des affichettes", "/manager/flyers" ]
    ]

    const handleMenuItemClick = (selectedItem: any) => {
        dispatch(ACT_MENU_SELECT_ITEM(selectedItem))
        if(setMobileOpen !== undefined) setMobileOpen(false)
        navigate(selectedItem)
    }

    // <Link key={index} to={x[1]} style={{ textDecoration: 'none' }} onClick={() => handleMenuItemClick(x[1])}>

    return (
        <div>
            <List
                sx={{ width: 240, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={<ListSubheader component="div" id="nested-list-subheader">Modules</ListSubheader>}>
                {menuItemsList.map((x: any, index: any) => (
                    <Link key={index} style={{ textDecoration: 'none' }} onClick={() => handleMenuItemClick(x[1])}>
                        <ListItemButton selected={selectedItem === x[1]}>
                            <ListItemIcon>
                                {x[2]}
                            </ListItemIcon>
                            <ListItemText primary={x[0]} />
                        </ListItemButton>
                    </Link>
                ))}
            </List>
        </div>
    )
}

export default Menu