import { Typography, ButtonGroup, ToggleButtonGroup, ToggleButton, Box, Paper, Chip, Button } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import Loader from '../../../components/Loader'
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import FlyerButton from "./FlyerButton"
import { useNavigate } from "react-router-dom"

function Home() {
    const navigate = useNavigate()
    const [ data, setData ] = useState<any>({})
    const [ isDataLoading, setDataLoading ] = useState(true)
    const [ showTheater, setShowTheater ] = useState<string | null>(localStorage.getItem('FlyersTheater') !== null ? localStorage.getItem('FlyersTheater') : '')
    const [ orderBy, setOrderBy ] = useState<string | null>(localStorage.getItem('FlyersOrder') !== null ? localStorage.getItem('FlyersOrder') : 'movie')

    const handleShowTheaterChange = (_event: React.MouseEvent<HTMLElement, MouseEvent>, newValue: any) => {
        localStorage.setItem('FlyersTheater', newValue)
        setShowTheater(newValue)
    }

    const handleOrderByChange = (_event: React.MouseEvent<HTMLElement, MouseEvent>, newValue: any) => {
        localStorage.setItem('FlyersOrder', newValue)
        setOrderBy(newValue)
    }

    useEffect(() => {
        document.title = "Cinapps - Affichettes"
        
        async function fetchFlyers() {
            setDataLoading(true)
            try {
                const response = await fetch(`https://api.cinapps.ch/flyer.php?showTheater=${showTheater}&orderBy=${orderBy}`)
                const { Cards } = await response.json()
                setData(Cards)
                //localStorage.setItem('Cards', JSON.stringify(Cards))
                //JSON.parse(localStorage.getItem('Cards'))
            } catch(err) {
                console.log(err)
            } finally {
                setDataLoading(false)
            }
        }

        fetchFlyers()
    }, [ setData, setDataLoading, showTheater, orderBy ])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: "20px" }}>Affichettes</Typography>
            <Box sx={{ display:'flex', alignItems:'center', flexWrap:'wrap' }}>
                <Typography paragraph sx={{ mr:2 }}>Cinéma:</Typography>
                <ToggleButtonGroup sx={{ mr:2, flexGrow:1 }} color="primary" value={showTheater} exclusive onChange={handleShowTheaterChange} style={{ marginBottom: "20px" }}>
                    <ToggleButton value="">Tous</ToggleButton>
                    <ToggleButton value="Cinematographe">Tramelan</ToggleButton>
                    <ToggleButton value="Royal">Tavannes</ToggleButton>
                </ToggleButtonGroup>
                <Typography paragraph sx={{ mr:2 }}>Trier par:</Typography>
                <ToggleButtonGroup color="primary" value={orderBy} exclusive onChange={handleOrderByChange} style={{ marginBottom: "20px" }}>
                    <ToggleButton value="movie">Film</ToggleButton>
                    <ToggleButton value="date">Date</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {isDataLoading ? (
                <Loader />
            ) : data !== undefined ? (
                <Grid2 container spacing={2}>
                {
                    data.map((x: any, index: any) => (
                        <Grid2 xs={12} lg={6} xl={4} xxl={3} xxxl={2} key={`Grid-${index}-${x.ShowTheater}-${x.MovieID}`}>
                            <Paper elevation={5} sx={{ p: 0.5 }}>
                                <Box sx={{ display:'flex', justifyContent:'center', flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
                                    <div>
                                        <img 
                                            style={{ maxHeight: "200px", maxWidth: "100%" }}
                                            src={x.Images[0].URL}
                                            alt={x.MovieTitle}
                                        />
                                    </div>
                                    <div style={{ flexGrow: 1, paddingLeft: "5px", paddingRight: "5px" }}>
                                        <Typography variant="h5" style={{ wordWrap: "break-word" }}>{x.MovieTitle}</Typography>
                                        <Typography paragraph={true}>{x.ShowTheater}</Typography>
                                        { x.Shows.map((show: any) => (
                                            <Chip key={`li-${show.ShowID}`} label={`${show.ShowDate} | ${show.ShowVersion}`} color="info" size="small" />
                                        ))}
                                        <br />
                                        { x.NbShows > 1 && (
                                            <Chip key={`li-${index}-${x.ShowTheater}-${x.MovieID}`} label={`+ ${x.NbShows-1} séances`} color="secondary" size="small" />
                                        )}
                                    </div>
                                </Box>
                                <Box sx={{ mt:2, display:'flex', alignItems: 'center', justifyContent:'center', flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
                                    { 
                                        //<ButtonGroup sx={{ flexGrow: 1 }} disableElevation orientation="horizontal" aria-label="vertical contained button group" variant="contained"> 
                                    }
                                        { x.Images.map((imgPub: any, indexFlyer: any) => indexFlyer === 0 ? null : (
                                            <FlyerButton key={`FlyerButton-${index}-${x.ShowTheater}-${x.MovieID}-${indexFlyer}`} imageUrl={imgPub.URL} description={imgPub.Description} backColor='primary' />
                                        )) }
                                        <Button sx={{ backgroundColor: '#000000' }} size="small" variant="contained" onClick={() => navigate('/flyers/edit/' + x.MovieID)}>Images</Button>
                                    {
                                        //</ButtonGroup>
                                    }
                                </Box>
                            </Paper>
                        </Grid2>
                    ))
                }
                </Grid2>
                ) : (
                    null
                )
            }
        </div>
    )
}

export default Home