import { Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import Loader from "../../../components/Loader"

function Manager() {
    const [ dataLoading, setDataLoading ] = useState(false)
    const [ data, setData ] = useState<any>([])
    let params = useParams()

    useEffect(() => {
        document.title = ""
        
        async function fetchFlyers() {
            setDataLoading(true)
            try {
                const response = await fetch(`https://api.cinapps.ch/flyerEdit.php?movieId=${params.movieId}`)
                const dataResponse = await response.json()
                setData(dataResponse)
            } catch(err) {
                console.log(err)
            } finally {
                setDataLoading(false)
            }
        }

        fetchFlyers()
    }, [ setData, setDataLoading, params.movieId ])

    return (
        <div>
        {
            dataLoading ? (
                <Loader />
            ) : data !== undefined ? (
                <div>
                    <Typography variant="h4">{data.movie_title}</Typography>
                    <Typography paragraph={true}>Logos</Typography>
                    {
                        data.movie_logos !== undefined ? (
                            data.movie_logos.map((x: any, index: any) => (
                                <img key={index} src={x} alt="" width={150} />
                            ))
                        ) : (
                            null
                        )                        
                    }
                    <Typography paragraph={true}>Posters</Typography>
                    {
                        data.movie_posters !== undefined ? (
                            data.movie_posters.map((x: any, index: any) => (
                                <img key={index} src={x} alt="" width={150} />
                            ))
                        ) : (
                            null
                        )                        
                    }
                    <Typography paragraph={true}>Captures</Typography>
                    {
                        data.movie_backdrops !== undefined ? (
                            data.movie_backdrops.map((x: any, index: any) => (
                                <img key={index} src={x} alt="" width={150} />
                            ))
                        ) : (
                            null
                        )                        
                    }
                </div>
            ) : (
                null
            )
        }
        </div>
    )
}

export default Manager