import { Typography, Divider, Link } from "@mui/material"

function Footer() {
    return (
        <div>
            <Divider sx={{mt:2}} />
            <Typography paragraph={true} sx={{ pt: 2, fontSize: '0.9rem' }}>
                © 2023 Kévin Carnal<br />
                Application Cinapps • version 2.0 bêta 3<br />
                <Link href="/page/privacy">Politique de confidentialité</Link>
            </Typography>
        </div>
    )
}

export default Footer