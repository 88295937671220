import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import FlyerHome from './applications/flyers/components/Home'
import FlyerManager from './applications/flyers/components/Manager'
import DcpKdmHome from './applications/dcp-kdm/components/Home'
import { theme } from './features/theme'
import store from './features/store'
import App from './components/App'
import LoginForm from './components/LoginForm'
import Home from './components/Home'
import HTMLPage from './components/HTMLPage'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App>
            <Routes>
              <Route path="/manager/flyers" element={<FlyerManager />} />
              <Route path="/flyers/edit/:movieId" element={<FlyerManager />} />
              <Route path="/flyers" element={<FlyerHome />} />
              <Route path="/dcp-kdm" element={<DcpKdmHome />} />
              <Route path="/user/login" element={<LoginForm />} />
              <Route path="/page/*" element={<HTMLPage />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </App>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
)