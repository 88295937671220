import { createReducer } from "@reduxjs/toolkit"
import { ACT_MENU_SELECT_ITEM, ACT_USER_CONNECT } from './actions'

export const menuReducer = createReducer(
    {}, 
    (builder) => {
        builder
        .addCase(ACT_MENU_SELECT_ITEM, (state: any, action) => {
            state.selectedItem = action.payload
        })
    }
)

export const userReducer = createReducer(
    {}, 
    (builder) => {
        builder
        .addCase(ACT_USER_CONNECT, (state: any, action) => {
            state.profile = action.payload
        })
    }
)