import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Loader from "./Loader"

const HTMLPage = () => {
    const location = useLocation()
    const [dataLoading, setDataLoading] = useState(false)
    const [pageData, setPageData] = useState<any>({})

    useEffect(() => {
        async function fetchPage() {
            setDataLoading(true)
            try {
                const response = await fetch(`https://api.cinapps.ch${location.pathname}`)
                if(response.status === 200){
                    const resultat = await response.json()
                    setPageData(resultat)
                }
            } catch(err) {
                console.log(err)
            } finally {
                setDataLoading(false)
            }
        }
        
        fetchPage()
    }, [location.pathname])

    return (
        <div>
        {
            dataLoading ? (
                <Loader />
            ) : pageData !== undefined ? (
                <div>
                    <Typography variant="h4" mb={2}>{pageData.page_title}</Typography>
                    <Typography component="div" paragraph>
                        <div dangerouslySetInnerHTML={{__html: pageData.page_body}} />
                    </Typography>
                </div>
            ) : (
                null
            )
        }
        </div>
    )
}

export default HTMLPage