import { AppBar, Toolbar, Typography, Box, Button, Drawer, IconButton, Icon } from "@mui/material"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from "react-router-dom"
import Footer from './Footer'
import Menu from "./Menu"
import { useState } from "react"
import { useSelector } from "react-redux"
import { selectConnectedUser } from "../features/selectors"
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import FeedbackButton from "./FeedbackButton"

function Header({ children }: any) {
    const [mobileOpen, setMobileOpen] = useState(false)
    const userProfile = useSelector(selectConnectedUser())
    const navigate = useNavigate()

    const handleMenuIconClick = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleMenuItemClick = (newLink: string) => {
        navigate(newLink)
    }

    return (
        <div>
            <FeedbackButton />
            <Box sx={{ display: 'flex' }}>
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ display: { xs: 'block', sm: 'none' }, mr: 2 }} onClick={handleMenuIconClick}>
                            <MenuIcon />
                        </IconButton>
                        <Icon>
                            <GroupWorkIcon />
                        </Icon>
                        <Typography variant="h5" component="div" sx={{ flexGrow: 1, display: { xs: 'block' } }}>
                            CINAPPS
                        </Typography>
                        <Box>
                            {
                                userProfile === undefined ? (
                                    <Button sx={{ color: 'inherit' }} onClick={() => handleMenuItemClick("/user/login")}>
                                        Connexion
                                    </Button>
                                ) : (
                                    <Button sx={{ color: 'inherit' }} startIcon={<AccountCircleIcon />}>
                                        {userProfile.firstName}
                                    </Button>
                                )
                            }
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer variant="temporary" open={mobileOpen} sx={{ width: 240 }} onClose={handleMenuIconClick}>
                    <Toolbar />
                    <Menu mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
                </Drawer>
                <Drawer variant="permanent" sx={{ display: { xs: 'none', sm:'block' }, width: 240 }}>
                    <Toolbar />
                    <Menu />
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 2, pb: 0 }}>
                    <Toolbar />
                    { children }
                    <Footer />
                </Box>
            </Box>
        </div>
    )
}

export default Header