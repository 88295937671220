import { createTheme } from '@mui/material'
import { red } from '@mui/material/colors'

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
      xs: true;
      sm: true;
      md: true;
      lg: true;
      xl: true;
      xxl: true;
      xxxl: true;
    }
  }

export const theme = createTheme({
    palette: {
        primary: {
            main: red[700]
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 2200,
            xxxl: 2900
        },
    },
})