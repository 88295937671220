import { useEffect, useState } from "react"
import { Typography, List, ListItem, ListItemIcon, ListItemText, IconButton, Box, TextField, Button } from "@mui/material"
import Loader from '../../../components/Loader'
import LocalMoviesIcon from '@mui/icons-material/LocalMovies'
import KeyIcon from '@mui/icons-material/Key'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { saveAs } from "file-saver"

function Home() {
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState<any>([])
    const [connexionLoading, setConnexionLoading] = useState(false)
    const [userConnected, setUserConnected] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")

    useEffect(() => {
        document.title = "Cinapps - DCP-KDM"
    }, [])

    const fetchFlyers = async () => {
        setDataLoading(true)
        try {
            const response = await fetch("https://api.cinapps.ch/dcp-kdm.php")
            const responseData = await response.json()
            setData(responseData)
            console.log(responseData)
        } catch(err) {
            console.log(err)
        } finally {
            setDataLoading(false)
        }
    }

    const fetchConnexion = async () => {
        setConnexionLoading(true)
        try {
            const response = await fetch("https://api.cinapps.ch/dcp-kdm/login", {
                method: "POST",
                body: JSON.stringify({ password })
            })
            if(response.status === 200){
                setPasswordError("")
                setUserConnected(true)
                fetchFlyers()
            } else {
                setPasswordError("Le mot de passe est erroné !")
                setUserConnected(false)
            }
        } catch(err) {
            setPasswordError("Erreur serveur !")
        } finally {
            setConnexionLoading(false)
        }
    }

    const handleKdmDownloadClick = (kdmLink: string) => {
        saveAs(kdmLink)
    }

    const onSubmitForm = (e: any) => {
        e.preventDefault()
        fetchConnexion()
    }

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: "20px" }}>DCP / KDM</Typography>
            {
                userConnected && dataLoading ? (
                    <Loader />
                ) : userConnected && data.DCP !== undefined ? (
                    <List dense>
                        {
                            data.DCP.map((dcp: any, index: any) => (
                                    <div>
                                        <ListItem key={index}>
                                            <ListItemIcon>
                                                <LocalMoviesIcon />
                                            </ListItemIcon>
                                            <ListItemText sx={{ wordWrap:'break-word' }} primary={dcp.Title} secondary={dcp.From} />
                                        </ListItem>
                                        {
                                            dcp.KDM !== undefined ? (
                                                <List sx={{ ml:8 }} dense>
                                                    {
                                                        dcp.KDM.map((kdm: any, index: any) => (
                                                            <ListItem key={index} sx={{ color: kdm.Valid ? 'inherit' : 'red' }}>
                                                                <ListItemIcon>
                                                                    <IconButton sx={{ color: kdm.Valid ? 'blue' : 'red' }} edge="start" onClick={() => handleKdmDownloadClick(kdm.File)}>
                                                                        <CloudDownloadIcon />
                                                                    </IconButton>
                                                                </ListItemIcon>
                                                                <ListItemText sx={{ wordWrap:'break-word' }} primary={kdm.Title} secondary={`Validité : ${kdm.ValidityStart} - ${kdm.ValidityEnd}`} />
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            ) : (
                                                <List sx={{ ml:8 }} dense>
                                                    <ListItem key={index} sx={{ color: 'red' }}>
                                                        <ListItemIcon sx={{ color: 'red' }}>
                                                            <KeyIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="KDM non trouvée !" />
                                                    </ListItem>
                                                </List>
                                            )
                                        }
                                    </div>
                                )
                            )
                        }
                    </List>
                ) : !userConnected ? (
                    <Box sx={{ display:'flex', justifyContent: 'left', flexDirection:'row', alignItems:'center' }}>
                        <form onSubmit={onSubmitForm}>
                            <TextField autoFocus={true} helperText={passwordError} error={passwordError !== ""} value={password} label="Mot de passe" type="password" required sx={{ m:2 }} onChange={(e) => setPassword(e.target.value)} />
                            <Button type="submit" disabled={connexionLoading} variant="contained" sx={{ m:2 }}>Connexion</Button>
                        </form>
                    </Box>
                ) : (null)
            }
            {
                data.KDM !== undefined ? (
                    <div>
                        <Typography variant="h5">KDM sans DCP</Typography>
                        <List dense>
                            {
                                data.KDM.map((kdm: any, index: any) => (
                                    <ListItem key={index} sx={{ color: kdm.Valid ? 'inherit' : 'red' }}>
                                        <ListItemIcon>
                                            <IconButton sx={{ color: kdm.Valid ? 'blue' : 'red' }} edge="end" onClick={() => handleKdmDownloadClick(kdm.File)}>
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText sx={{ wordWrap:'break-word' }} primary={kdm.Title} secondary={`Validité : ${kdm.ValidityStart} - ${kdm.ValidityEnd}`} />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </div>
                ) : (null)
            }
        </div>
    )
}

export default Home