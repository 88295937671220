import { Button, IconButton } from "@mui/material"
import { useState } from "react"
import Loader from '../../../components/Loader'
import { saveAs } from "file-saver"

type Props = {
    description: string,
    imageUrl: string,
    backColor: string
}

function FlyerButton({ description, imageUrl, backColor }: Props) {
    const [ isDataLoading, setDataLoading ] = useState(false)
    const [ isDisabled, setDisabled ] = useState(false)
    
    const generateFlyer = (imgURL: string, imgName: string) => {
        async function fetchFlyers() {
            setDataLoading(true)
            setDisabled(true)
            try {
                const response = await fetch(imgURL)
                const imageBlob = await response.blob()
                const imageObjectURL = URL.createObjectURL(imageBlob)
                saveAs(imageObjectURL, imgName)
            } catch(err) {
                console.log(err)
            } finally {
                setDisabled(false)
                setDataLoading(false)
            }
        }
        fetchFlyers()
    }

    return (
        <Button sx={{ mr:1, backgroundColor: backColor }} size="small" onClick={() => generateFlyer(imageUrl, description)} variant="contained" disabled={isDisabled}>
            { description }
            { isDataLoading && ( <Loader /> ) }
        </Button>
    )
}

export default FlyerButton