import { configureStore } from "@reduxjs/toolkit"
import { menuReducer, userReducer } from './reducers'

const store = configureStore({
    reducer: {
        menu: menuReducer,
        user: userReducer
    }
})

export default store