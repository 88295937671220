import { TextField, Typography, Button, Box } from "@mui/material"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { ACT_MENU_SELECT_ITEM } from "../features/actions"

function LoginForm() {
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = "Cinapps - Connexion"
        dispatch(ACT_MENU_SELECT_ITEM())
    }, [dispatch])

    return (
        <Box sx={{ display:'flex', justifyContent: 'center', flexDirection:'column', alignItems:'center' }}>
            <Typography variant="h4" sx={{ textAlign:'center' }}>Connexion</Typography>
            <TextField autoFocus={true} label="Utilisateur" required sx={{ m:2 }} /> 
            <TextField label="Mot de passe" type="password" required sx={{ m:2 }} />
            <Button variant="contained" sx={{ m:2 }}>Connexion</Button>
        </Box>
    )
}

export default LoginForm